<template>
	<div class="join">
		<div class="join-img">
			<div class="join-lable">
				<div>朝阳行业</div>
				<div>全程帮扶</div>
				<div>政策支持</div>
				<div>无需经验</div>
				<div>利润高</div>
				<div>投资小</div>
				<div>门槛低</div>
				<div>需求大</div>
			</div>
			<img src="@/assets/joinImg/guangaoyu.png" alt="" />
			<div class="join-code">
				<div class="jion-img">
					<div>
						<img src="@/assets/joinImg/weixCode.png" alt=""/>
					</div>
					<div class="scan">
						<div style="margin-top: 0;">微信扫一扫</div>
						<div>咨询合作加盟</div>
					</div>
				</div>
				<div class="jion-text">前30家门店享受0元 合作加盟</div>
			</div>
		</div>
		<div class="join-title">
			<img src="@/assets/joinImg/lefticon.png" alt=""/>
			<div>照顾你所关爱的一切 | 让爱充满每一个家庭</div>
			<img src="@/assets/joinImg/righticon.png" alt=""/>
		</div>
		<div class="join-con">
			<div class="con-text">引入欧美家庭服务企业成功的服务模式</div>
			<div class="con-line">
				<div class="line-left"></div>
				<div class="line-right"></div>
			</div>
			<div class="con-text">携手美国家政行业巨头</div>
		</div>
		<div class="text">2019年12月，心巢与全球最大家政公司Care.com、Sittercity、UrbanSitter三家企业进行深入调研与学习，确立了去中介化、人员库+标签化寻找+安全筛查工具的经营模式。心巢不但引进了他们先进的服务模式和系统化的培训体系，也引进了他们的全套工具，为了本土化体验更佳，适配特有的中国互联网家政服务模式，对自身的产品线也进行了更加细致的划分和完善。</div>
		<div class="join-card">
			<div class="card">
				<div class="card-box">
					
					<div class="box-img">
						<div class="img-back">
							<div style="padding-top: 30%;">心巢</div>
							<div style="margin-top: 20px;">用心去连接每个家庭</div>
						</div>
					</div>
					<div class="box-text">良心品牌</div>
				</div>
			</div>
			<div class="card">
				<div class="card-box">
					<div class="box-img2">
						<div class="img-back">
							<div style="padding-top: 30%;">全网推广渠道</div>
							<div style="margin-top: 20px;">海量获取订单</div>
						</div>
					</div>
					<div class="box-text">海量订单</div>
					
				</div>
			</div>
			<div class="card">
				<div class="card-box">
					<div class="box-img3">
						<div class="img-back">
							<div style="padding-top: 30%;">拥有专业的劳动者资源</div>
							<div style="margin-top: 20px;">实现需求快速匹配</div>
						</div>
					</div>
					<div class="box-text">海量资源</div>					
				</div>
			</div>
		</div>
		<div class="join-card">
			<div class="card">
				<div class="card-box">
					<div class="box2-img">
						<div class="img-back">
							<div style="padding-top: 30%;">引入欧美发达国家先进模式</div>
							<div style="margin-top: 20px;">本地化升级适配</div>
						</div>
					</div>					
					<div class="box-text">模式优势</div>
				</div>
			</div>
			<div class="card">
				<div class="card-box">
					<div class="box2-img2">
						<div class="img-back">
							<div style="padding-top: 30%;">搭建线上平台：五端+智慧云</div>
							<div style="margin-top: 20px;">便捷高效处理家政业务信息</div>
						</div>
					</div>
					<div class="box-text">O2O多维度智慧系统</div>
				</div>
			</div>
			<div class="card">
				<div class="card-box">
					<div class="box2-img3">
						<div class="img-back">
							<div style="padding-top: 30%;">行业定制家政服务保险</div>
							<div style="margin-top: 20px;">劳动者背景调查，有效降低风险</div>
						</div>
					</div>
					<div class="box-text">风险控制</div>								
				</div>
			</div>
		</div>
		<div class="join-card">
			<div class="card">
				<div class="card-box">
					<div class="box3-img">
						<div class="img-back">
							<div style="padding-top: 30%;">六大政策</div>
							<div style="margin-top: 20px;">全方位、全过程、全流量、全服务帮扶</div>
						</div>
					</div>
					<div class="box-text">服务优势</div>						
				</div>
			</div>
			<div class="card">
				<div class="card-box">
					<div class="box3-img2">
						<div class="img-back">
							<div style="padding-top: 30%;">名师汇聚教学，“0”门槛加入</div>
							<div style="margin-top: 20px;">全方位专业指导、赋能</div>
						</div>
					</div>
					<div class="box-text">专业培训</div>	
					
				</div>
			</div>
			<div class="card">
				<div class="card-box">
					<div class="box3-img3">
						<div class="img-back">
							<div style="padding-top: 30%;">名师汇聚教学，“0”门槛加入</div>
							<div style="margin-top: 20px;">全方位专业指导、赋能</div>
						</div>
					</div>
					<div class="box-text">多渠道宣传</div>						
				</div>
			</div>
		</div>
		<div class="online-platform">
			<div class="join-con" style="padding-top: 1px;">
				<div class="con-text" style="margin-top: 80px;">线上平台    <span style="margin-left: 20px;">五端一云</span>    <span style="margin-left: 20px;">重新定义信息与资源</span></div>
				<div class="con-line">
					<div class="line-left"></div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="tree-img">
				<div class="tree-box">
					<div class="fbox">
						<img src="@/assets/joinImg/SRM.png" alt=""/>
						<div>心巢通过SRM供应商管理系统开放自身用户资源和流量，接入更多第三方垂直O2O服务提供商，共享内容、共享流量，深度打造“服务联盟”。</div>
					</div>
					<img class="arrow-img" src="@/assets/joinImg/p_arrow_left.png" alt="" />
					<div class="fbox">
						<img class="fbox-img" src="@/assets/joinImg/gongyingshang.png" alt=""/>
						<div class="fbox-title">上游供应商提供优质货源</div>
						<div class="fbox-box">
							<div class="fbox-text" style="margin-left: 0;"><span>团购<br/>零售</span></div>							
							<div class="fbox-text"><span>团购<br/>零售</span></div>	
							<div class="fbox-text"><span>团购<br/>零售</span></div>	
							<div class="fbox-text" style="margin-left: 0;"><span>团购<br/>零售</span></div>	
							<div class="fbox-text"><span>团购<br/>零售</span></div>	
							<div class="fbox-text"><span>团购<br/>零售</span></div>	
						</div>
					</div>
				</div>
				<img class="arrow-botton" src="@/assets/joinImg/p_arrow_botton.png" alt="" />				
				<div class="cloud">
					<div class="cloud-title">心巢智慧云</div>
					<div class="cloud-con">心巢智慧云汇集OMS、SRM、APP和微信小程序的系统数据，通过对用户行为、生活轨迹、个人偏好、历史购买记录等进行大数据分析，精准挖掘用户的潜在需求，充分发挥各端协同效应，进而创造出更大的商业价值。</div>
				</div>
				<div class="arrow-b">
					<img class="arrow-left" src="@/assets/joinImg/xueleft.png" alt=""/>
					<img class="img1" src="@/assets/joinImg/updown.png" alt=""/>
					<img class="img2" src="@/assets/joinImg/updown.png" alt=""/>
					<img class="arrow-right" src="@/assets/joinImg/xueright.png" alt=""/>
				</div>
				<div class="app-box">
					<div class="box-platform">
						<img src="@/assets/joinImg/OMS.png" alt=""/>
						<div>心巢通过OMS运营管理系统对门店提供订单分配、人员匹配、工单派发、面试签约、财务收款、服务跟踪、服务师资源库、培训管理、保险投保、工资核算、客户营销、报表统计等日常运营的全线系统支持。</div>
					</div>
					<div class="box-platform">
						<img src="@/assets/joinImg/APP_woker.png" alt=""/>
						<div>服务师通过员工版APP接收公告通知、推荐工作、面试提醒、管理合同、查看工资单、记录工作情况、报名参加培训，实现高效的工作管理。</div>
					</div>
					<div class="box-platform">
						<img src="@/assets/joinImg/APP_customer.png" alt=""/>
						<div>社区居工通过顾客版APP提交订单、查看简历、预约面试、管理合同、结算工资、支付费用、查看消费记录、反馈评价信息、实现全流程业务闭环。</div>
					</div>
					<div class="box-platform">
						<img src="@/assets/joinImg/weixin.png" alt=""/>
						<div>社区居民可以通过微信小程序，轻量化使用家政和健康护理服务，一键快速保洁、一键快速发布家政护理需求，减少下载APP成本，轻量化，服务不打折。</div>
					</div>
				</div>
				<div style="position: relative;">
					<div class="line-updown">
						<img class="img1" src="@/assets/joinImg/updown.png" alt=""/>
						<img class="img2" src="@/assets/joinImg/updown.png" alt=""/>
						<img class="img2" src="@/assets/joinImg/updown.png" alt=""/>
					</div>
					<div class="pai-img">
						<img class="sheju" src="@/assets/joinImg/shejushore.png" alt=""/>
						<div class="arrow-right">
							<div>派工</div>
							<img src="@/assets/joinImg/p_right.png" alt=""/>
						</div>
						<img src="@/assets/joinImg/fuwushi.png" alt=""/>
						<div class="arrow-right">
							<div>上门服务</div>
							<img src="@/assets/joinImg/p_right.png" alt=""/>
						</div>
						<img src="@/assets/joinImg/jumin.png" alt=""/>					
						<img class="zhuan-img" src="@/assets/joinImg/zhuanzhe.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="con-online">
			<div class="join-con" style="margin:0;">
				<div class="con-text" style="margin-top: 80px;">线下实体    <span style="margin-left: 20px;">三级体系</span>   <span style="margin-left: 20px;">构建高效服务网络</span></div>
				<div class="con-line">
					<div class="line-left"></div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="con-grid">
				<div class="grid-card">
					<div class="grid-box">
						<div class="grid-title">总部</div>
						<div class="grid-text">总部集品牌中心、运营中心、培训中心、网络中心、呼叫中心于一体，为城市运营中心、社区门店制定战略方向，整合、调整企业资源，提供服务和智力支持。</div>
						
					</div>
					<div class="grid-box">
						<div class="grid-title">城市运营中心</div>
						<div class="grid-text">城市运营中心进行区域网点规划与拓展，对门店提供服务师培训、品牌宣传执行、门店管理等一整套培训及运营支持。</div>
					</div>
					<div class="grid-box">
						<div class="grid-title">社区门店</div>
						<div class="grid-text">社区门店，直接面向社区居民和服务师。完成招聘服务师、报名培训、匹配订单、派发工单、面试签约、投保保险、核算工资、跟踪服务等店内日常业务管理工作。</div>
					</div>
				</div>
				<div class="grid-card">
					<div class="grid-top">
						<div class="grid-line"></div>
						<div class="g-top-left">
							<div>运营中心</div>							
							<div>网络中心</div>
						</div>
						
						<div class="back-img">
							<div>品牌<br/>中心</div>
						</div>
						<div class="g-top-left">
							<div>培训中心</div>							
							<div>呼叫中心</div>
						</div>
					</div>
					<img class="grid-img" src="@/assets/joinImg/huisearrow.png" alt="">
					<div class="grid-conter">
						<div class="conter-box">							
							<div class="c-text">运营<br/>中心</div>
							<div class="c-line"></div>
							<div class="c-text">培训<br/>中心</div>
						</div>
						<div class="conter-box">
							<div class="c-text">运营<br/>中心</div>
							<div class="c-line"></div>
							<div class="c-text">培训<br/>中心</div>
						</div>
					</div>
					<div class="grid-img2">
						<img class="img_arrow1" src="@/assets/joinImg/huisemin.png" alt="" />
						<img class="img_arrow2" src="@/assets/joinImg/huisemin.png" alt="">
					</div>
					<div class="grid-foot">
						<div class="g-foot-box">
							<div>社区<br/>门店</div>
						</div>
						<div class="g-foot-box"><div>社区<br/>门店</div></div>
						<div class="g-foot-box"><div>社区<br/>门店</div></div>
						<div class="g-foot-box"><div>社区<br/>门店</div></div>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;">
			<img style="width: 100%;" src="@/assets/joinImg/xiangbanBg.jpg" alt=""/>
		</div>
		
		<div class="graft">
			<div class="join-con" >			
				<div class="con-line">
					<div class="line-left"></div>
					<div class="lin-title">嫁接好平台</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="graft-text">以家政服务为切入点纵向深入，前期构筑起服务和信任度的护城河，后期依靠业已形成的客户需求黏性，拓展上下游产业链、嫁接多种产品和服务，以社区综合平台模式进行横切起量。</div>
			
			<div style="width: 1500px;margin: 0 auto;">
				<div class="terminal" style="margin-top: 100px;">
					<img src="@/assets/joinImg/user.png" alt="">
					<div>终端用户的综合需求</div>
				</div>	
			</div>
			<div style="width: 1500px;margin: 0 auto;">
				<div class="shuline"></div>
			</div>
			<div style="width: 1500px;margin: 0 auto;">
				<div class="hengline"></div>
			</div>
			
			<div style="width: 1500px;margin: 0 auto;">
				<div class="minshuline">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			
			<div style="width: 1500px;margin: 0 auto;">
				<div class="line-title">
					<div>家务服务</div>
					<div>保洁服务</div>
					<div>育婴早教</div>
					<div>母婴护理</div>
					<div>养老病护</div>
				</div>
			</div>
			
			<div style="width: 1500px;margin: 0 auto;">
				<div class="lot-line">
					<div class="shu" style="margin-left: 100px;"></div>
					<div class="xie-left" style="margin-left: 60px;"></div>
					<div class="xie-right" style="margin-left: 90px;"></div>				
					<div class="shu" style="margin-left: 90px;"></div>
					<div class="shu" style="margin-left: 250px;"></div>				
					<div class="xie-left" style="margin-left: 70px;"></div>
					<div class="xie-right" style="margin-left: 90px;"></div>				
					<div class="xie-left" style="margin-left: 160px;"></div>
					<div class="xie-right" style="margin-left: 90px;"></div>				
					<div class="shu" style="margin-left: 70px;"></div>
					<div class="xie-left" style="margin-left: 80px;"></div>
				</div>
			</div>
			<div class="lot-radius">
				<div style="margin-left: 210px;"><span>生鲜<br/>配送</span></div>
				<div style="margin-left: 25px;"><span>箱包<br/>护理</span></div>
				<div style="margin-left: 55px;"><span>家具<br/>养护</span></div>
				<div style="margin-left: 170px;"><span>社区<br/>教育</span></div>
				<div style="margin-left: 35px;"><span>母婴<br/>用品</span></div>
				<div style="margin-left: 175px;"><span>营养<br/>配餐</span></div>
				<div style="margin-left: 40px;"><span>社区<br/>医院</span></div>
				<div style="margin-left: 50px;"><span>康复<br/>器材</span></div>				
			</div>
			<div class="lot-line" style="width: 1500px;">				
				<div class="xie-right" style="margin-left: 210px;height: 40px;"></div>				
				<div class="xie-left" style="margin-left: 75px;height: 40px;"></div>
				<div class="xie-left" style="margin-left: 95px;height: 40px;"></div>
				<div class="xie-right" style="margin-left: 65px;height: 40px;"></div>
				<div class="shu" style="margin-left: 290px;height: 40px;"></div>
				<div class="shu" style="margin-left: 500px;height: 40px;"></div>
				<div class="shu" style="margin-left: 140px;height: 40px;"></div>																
			</div>
			<div class="lot-radius">
				<div style="margin-left: 140px;"><span>社区<br/>团购</span></div>
				<div style="margin-left: 60px;"><span>物流<br/>服务</span></div>
				<div style="margin-left: 15px;"><span>清洁<br/>用品</span></div>
				<div style="margin-left: 245px;"><span>入户<br/>教育</span></div>
				<div style="margin-left: 415px;"><span>养生<br/>保健</span></div>
				<div style="margin-left: 60px;"><span>养老<br/>旅游</span></div>							
			</div>
		</div>
		<div class="profit">
			<div class="join-con" style="padding-top: 100px;" >
				<div class="con-line">
					<div class="line-left"></div>
					<div class="lin-title">利润来源</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="profit-title">八大利润来源,开业就能赚不停</div>
			<div class="lot-line2">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div class="lot-radius2">
				<div>1</div>
				<div>2</div>
				<div>3</div>
				<div>4</div>
				<div>5</div>
				<div>6</div>
				<div>7</div>
				<div>8</div>
			</div>
			<div class="profit-box">
				<div class="profit-card">
					<div>客户</div>
					<div>渠道</div>
					<div>收益</div>
				</div>
				<div class="profit-card">
					<div>客户</div>
					<div>渠道</div>
					<div>收益</div>
				</div>				  
				<div class="profit-card">
					<div>人员</div>
					<div>培训</div>
					<div>收益</div>
				</div>				   
				<div class="profit-card">
					<div>人员</div>
					<div>安置</div>
					<div>就业</div>
					<div>收益</div>
				</div>
				   
				<div class="profit-card">
					<div>旅游</div>
					<div>产品</div>
					<div>代销</div>
					<div>收益</div>
				</div>				   
				<div class="profit-card">
					<div>微商城</div>
					<div>商品</div>
					<div>销售</div>
					<div>收益</div>
				</div>				   
				<div class="profit-card">
					<div>微信</div>
					<div>朋友圈</div>
					<div>广告</div>
					<div>收益</div>
				</div>				   
				<div class="profit-card">
					<div>总部</div>
					<div>持续开发</div>
					<div>其他增值</div>
					<div>项目收益</div>
				</div>
			</div>
		</div>
		<div class="core-adv">
			<div class="join-con2">
				<div class="con-line">
					<div class="line-left"></div>
					<div class="lin-title">核心优势</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="core-box" style="margin-top: 100px;">
				<div class="core-img">
					<div>统一的</div>
					<div>品牌VI设计</div>
				</div>
			</div>
			<div class="core-box" >
				<div class="core-img img-l">
					<div>统一的</div>				 
					<div>店面管理体系</div>
				</div>
				<div class="core-img img-l">
					<div>统一的</div>
					<div>服务标准体系</div>
				</div>				
			</div>
			<div class="core-box" style="padding-bottom: 100px;">
				<div class="core-img img-2">
					<div>统一的</div>
					<div>运营培训体系</div>
				</div>
				<div class="core-img img-2">
					<div>统一的</div>
					<div>营销管理体系</div>
				</div>	
				<div class="core-img img-2">
					<div>统一的</div>
					<div>业务管理流程</div>
				</div>
			</div>
		</div>
		<div class="policy">
			<div class="join-con" style="padding-top: 100px;margin-top: 0;" >
				<div class="con-line">
					<div class="line-left"></div>
					<div class="lin-title">政策支持</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="policy-text">总部为您提供品牌、运营、培训、推广、资源、技术等全套支持，更对接全国优质商业资源与您分享，解您后顾之忧，成为您最坚强的后盾。</div>
			<div class="policy-box">
				<div class="box-f">
					<div class="box-top">
						<img  style="width: 70px;" src="@/assets/joinImg/pinppai.png" alt=""/>
						<div>品牌支持</div>
					</div>
					<div class="box-foot">
						<div>VIS系统  品牌商标</div>
						<div>管理模式  品牌价格</div>
					</div>
				</div>
				<div class="box-f">
					<div class="box-top">
						<img  style="width: 88px;" src="@/assets/joinImg/yunying.png" alt=""/>
						<div>运营支持</div>
					</div>
					<div class="box-foot">
						<div>投资分析  选址分析</div>
						<div>运营手册  现场管理</div>
					</div>
				</div>
				<div class="box-f">
					<div class="box-top">
						<img  style="width: 80px;" src="@/assets/joinImg/peixun.png" alt=""/>
						<div>培训支持</div>
					</div>
					<div class="box-foot">
						<div>经营理念  服务技能</div>
						<div>推广手段  管理技巧</div>
					</div>
				</div>
			</div>
			<div class="policy-box" style="margin-top: 50px;padding-bottom: 100px;">
				<div class="box-f">
					<div class="box-top">
						<img  style="width: 78px;" src="@/assets/joinImg/tuiguang.png" alt=""/>
						<div>推广支持</div>
					</div>
					<div class="box-foot">
						<div>官网推广  微信推广</div>
						<div>线下活动  媒体公关</div>
					</div>
				</div>
				<div class="box-f">
					<div class="box-top">
						<img  style="width: 86px;" src="@/assets/joinImg/ziyuan.png" alt=""/>
						<div>资源支持</div>
					</div>
					<div class="box-foot">
						<div>保险公司  宣传渠道</div>
						<div>社会团队  人力资源</div>
					</div>
				</div>
				<div class="box-f">
					<div class="box-top">
						<img  style="width: 75px;" src="@/assets/joinImg/jishu.png" alt=""/>
						<div>技术支持</div>
					</div>
					<div class="box-foot">
						<div>ERP系统  订单系统</div>
						<div>人资系统  财务系统</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="resource">
			<div class="join-con"  >
				<div class="con-line">
					<div class="line-left"></div>					
					<div class="lin-title">资源支持</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="resource-con">
				<img src="@/assets/joinImg/fiveziyuan.png" alt=""/>
				<div class="resource-text">
					<div>整合优势资源 <br />实现渠道对接</div>
				</div>
				<div class="resource-text2">
					<div>服务师资源库<br />同城共享资源</div>
				</div>
				<div class="resource-text3">
					<div>携手保险公司<br />免除责任风险</div>
				</div>
				<div class="resource-text4">
					<div>常年法律顾问<br />提供法律咨询</div>
				</div>
				<div class="resource-text5">
					<div>连接呼叫中心<br />受理客户诉求</div>
				</div>
			</div>
		</div>
		<div class="condition">
			<div class="join-con"  >
				<div class="con-line">
					<div class="line-left"></div>
					<div class="lin-title">合作条件</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="condition-con">
				<div class="con-left">
					<div style="border-top: 0;">合作方式及费用要求</div>
					<div>店面要求</div>
					<div>人员要求</div>
					<div>店面形象要求</div>
					<div>项目启动准备</div>
				</div>
				<div class="con-right">
					<div style="height: 78px;">城市代理合作：公司品牌授权；年度合作协议：缴纳年度合作费用</div>
					<div>面积：15平米以上；位置：城市医院附近底商、其他办公楼内；功能：满足办公，培训，会议，洽谈需要</div>
					<div>按照角色分工配置，启动团队不少于3人；后期根据情况增加人员会使用电脑和网络、能够接受公司培训</div>
					<div>按照心巢统一店面设计、装修；悬挂心巢品牌标识</div>
					<div style="height: 78px;">启动资金5万元及以上；注册资本5万元及以上</div>
				</div>
			</div>
		</div>
		<div class="flow">
			<div class="join-con" >
				<div class="con-line">
					<div class="line-left"></div>
					<div class="lin-title">合作流程</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="flow-box">
				<div>提交申请</div>
				<div>选址</div>
				<div>内部评估</div>
				<div>签约</div>
				<div>装修</div>
				<div>培训</div>
				<div>开业</div>
				<div>全程帮扶</div>
			</div>
			<div class="foot-telphon">合作加盟服务电话：<span>15278887598</span></div>
		</div>
		<div class="foot-store">
			<div class="join-con"  >
				<div class="con-line">
					<div class="line-left"></div>
					<div class="lin-title">心巢门店</div>
					<div class="line-right"></div>
				</div>				
			</div>
			<div class="store-img store-image">
				<img src="@/assets/joinImg/store01.jpg" alt="" />
			</div>
			<div class="store-img">
				<img src="@/assets/joinImg/store02.jpg" alt="" />
				<img src="@/assets/joinImg/store03.jpg" alt="" />
				<img src="@/assets/joinImg/store04.jpg" alt="" />
			</div>
			<div class="store-img">
				<img src="@/assets/joinImg/store05.jpg" alt="" />
				<img src="@/assets/joinImg/store06.jpg" alt="" />
				<img src="@/assets/joinImg/store07.jpg" alt="" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
.join{
	width: 100%;
	.join-img{
		width: 100%;
		background-image: url('@/assets/joinImg/joinbanner.jpg');
		background-size: cover;
		background-position: center;
		height: 900px;
		.join-lable{
			width: 650px;
			margin-left: 16%;
			padding-top: 70px;
			height: 150px;
			div{
				width: 136px;
				height: 48px;
				background: #797C7E;
				border-radius: 8px;
				float: left;
				border: 1px solid #FFFFFF;
				color: #fff;
				text-align: center;
				line-height: 48px;
				margin-left: 20px;
				margin-top: 20px;
			}
		}
		img{			
			margin: 130px auto;
			display: block;
		}
		.join-code{
			width: 300px;
			margin-left: 16%;
			
			.jion-img{
				width: 100%;
				height: 168px;
				background: #4E4E50;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				img{
					width: 120px;
					height: 120px;
					
				}
				.scan{
					margin-left: 20px;
					color: #fff;
					div{
						margin-top: 10px;
					}
				}
			}
			.jion-text{
				width: 100%;
				height: 50px;
				text-align: center;
				line-height: 50px;
				color: #fff;
			}
		}
	}	
	.join-title{
		width: 100%;
		height: 128px;
		background: #FC526C;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 36px;
		color: #fff;
		img{
			width: 24px;
			height: 24px;
		}
		div{
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	.join-con{
		width: 100%;
		margin-top: 100px;
		.con-text{
			width: 100%;
			text-align: center;
			color: #333333;
			font-size: 30px;
			font-weight: bold;
		}
		.con-line{
			display: flex;
			width: 100%;
			justify-content: center;
			margin: 10px auto;
			.lin-title{
				width: 300px;				
				line-height: 40px;
				text-align: center;				
				height: 40px;
				font-size: 36px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #333333;
				margin-top: -25px;
			}
			.line-left{
				width: 600px;
				height: 2px;
				background:linear-gradient(to right, #FFFFFF, #000000);				
			}
			.line-right{
				width: 600px;
				height: 2px;
				background: linear-gradient(to left, #FFFFFF, #000000);
				
			}
		}
	}
	.text{
		width: 1200px;		
		font-size: 24px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #333333;
		line-height: 36px;
		margin: 100px auto;
	}
	.join-card{
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: 50px auto;
		.card{
			width: 368px;
			height: 368px;
			background: #FFFFFF;
			border-radius: 8px;			
			border: 1px solid #000000;
			display: flex;
			justify-content: center;
			align-items: center;
			.card-box{
				width: 344px;
				height: 344px;
				.box-img{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/liangxin.jpg');
					background-size: cover;
					background-position: center;
					
				}
				
				.box-img2{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/lotOrder.jpg');
					background-size: cover;
					background-position: center;
					
				}
				.box-img3{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/lotZiyuan.jpg');
					background-size: cover;
					background-position: center;
					
				}
				.box2-img{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/modelYoushi.jpg');
					background-size: cover;
					background-position: center;					
				}
				.box2-img2{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/O2O.jpg');
					background-size: cover;
					background-position: center;
					
				}
				.box2-img3{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/risk.jpg');
					background-size: cover;
					background-position: center;
					
				}
				.box3-img{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/service.jpg');
					background-size: cover;
					background-position: center;					
				}
				.box3-img2{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/peixun.jpg');
					background-size: cover;
					background-position: center;					
				}
				.box3-img3{
					width: 100%;
					height: 280px;
					background-image: url('@/assets/joinImg/xuanchuang.jpg');
					background-size: cover;
					background-position: center;					
				}
				.box-text{
					font-size: 24px;
					width: 100%;
					height: 64px;
					text-align: center;
					line-height: 64px;
					background: #FC526C;
					color: #fff;
				}
				.img-back{
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.5);
					div{
						color: #fff;
						width: 100%;
						text-align: center;
						font-size: 18px;
					}
				}
			}
		}
	}
	.online-platform{
		width: 100%;
		background: #F8F9FB;		
		margin-top: 90px;
		padding-bottom: 30px;
		.tree-img{
			width: 1200px;
			margin: 80px auto;
			.tree-box{
				width: 100%;
				display: flex;
				justify-content: right;
				align-items: center;
				.fbox{
					width: 320px;
					height: 320px;
					background: #F8F9FB;
					border-radius: 8px;					
					border: 1px solid #A1A2A3;
					img{
						width: 132px;
						height: 128px;
						margin: 10px auto;
						display: block;
						margin-top: 30px;
					}
					div{
						margin: 0 auto;
						width: 270px;						
						font-size: 18px;					
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #666666;
						line-height: 24px;						
					}
					.fbox-img{
						width: 212px;
						height: 56px;
						margin: 10px auto;
						display: block;
						margin-top: 30px;
					}
					.fbox-box{						
						height: 13opx;
						margin: 0px auto;
						margin-top: 10px;
						width: 270px;	
						.fbox-text{
							width: 64px;
							margin-top: 15px;
							height: 64px;
							float: left;
							background: #F8F9FB;
							border-radius: 50%;
							border: 1px solid #A1A2A3;
							text-align: center;
							font-size: 16px;
							margin-left: 30px;
							span{
								display: block;
								margin-top: 7px;
							}
						}
					}
					.fbox-title{
						width: 100%;
						text-align: center;
						color: #FC526C;
						font-size: 18px;
					}
				}
				.arrow-img{
					width: 148px;
					height: 6px;
				}
			}
			.arrow-botton{
				display: block;
				width: 6px;
				height: 75px;
				margin-left: 570px;
			}
		}
		.cloud{
			width: 578px;
			height: 320px;
			margin-left: 320px;
			background-image: url('@/assets/joinImg/cloudBG.png');
			background-size: cover;
			background-position: center;
			.cloud-title{
				
				text-align: center;
				font-size: 48px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #FFFFFF;
				padding-top: 100px;
				line-height: 36px;
			}
			.cloud-con{
				width: 449px;
				height: 91px;
				font-size: 18px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				margin: 50px auto;
			}
			
		}
		.arrow-b{
			display: flex;			
			.arrow-left{
				width: 189px;
				height: 119px;
				margin-left: 140px;
				margin-top: -40px;
			}
			.img1{
				margin-left: 120px;
				
			}
			.img2{
				margin-left: 300px;
				
			}
			.arrow-right{
				width: 189px;
				height: 119px;
				margin-top: -40px;
				margin-left: 128px;
			}
		}
		.app-box{
			width: 100%;
			display: flex;
			justify-content: space-between;
			.box-platform{
				width: 282px;
				height: 392px;
				background: #F8F9FB;
				border-radius: 8px;				
				border: 1px solid #A1A2A3;
				img{
					
					height: 128px;
					display: block;
					margin: 0 auto;
					margin-top: 30px;
				}
				div{
					width: 233px;
					height: 186px;
					font-size: 18px;
					margin: 0 auto;
					font-family: SourceHanSansCN-Regular, SourceHanSansCN;
					font-weight: 400;
					color: #666666;
					line-height: 24px;
					margin-top: 20px;
				}
			}
		}
		.line-updown{
			display: flex;
			.img1{
				margin-left: 130px;
			}
			.img2{
				margin-left: 300px;
			}
			
		}
		.pai-img{
			display: flex;
			.sheju{
				margin-left: 50px;
			}
			.arrow-right{
				margin-top: 40px;
				div{
					width: 100%;
					text-align: center;
				}
			}
			.zhuan-img{
				position: absolute;
				top: 0;
				right: 150px;
			}
		}
	}
	.con-online{
		width: 100%;
		padding-bottom: 50px;
		.con-grid{
			width: 1200px;
			display: flex;
			margin: 0 auto;
			justify-content: space-between;
			.grid-card{
				width: 480px;				
				margin-top: 50px;
				.grid-box{
					width:100%;
					height: 168px;
					background: #F8F9FB;
					border-radius: 8px;					
					border: 1px solid #A6A6A6;
					margin-top: 20px;
					.grid-title{						
						height: 24px;
						font-size: 24px;
						font-family: SourceHanSansCN-Bold, SourceHanSansCN;
						font-weight: bold;
						color: #333333;						
						margin-left: 25px;
						margin-top: 25px;
					}
					.grid-text{
						width: 413px;
						height: 72px;
						font-size: 16px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #666666;
						line-height: 25px;
						margin-left: 25px;
						margin-top: 25px;
					}
				}
				.grid-top{
					width: 480px;
					height: 106px;
					background: #FFFFFF;
					border-radius: 8px;					
					border: 2px solid #FD7589;
					margin-top: 25px;
					position: relative;
					display: flex;
					.g-top-left{
						div{
							width: 181px;							
							text-align: center;
							font-size: 24px;
							font-family: SourceHanSansCN-Regular, SourceHanSansCN;
							font-weight: 400;
							color: #FC526C;
							line-height: 53px;
						}
					}
					.grid-line{
						position: absolute;
						width: 462px;
						height: 2px;
						top: 53px;
						background: #FD7589;
						left: 9px;
					}
					.back-img{
						width: 118px;
						height: 100%;
						background-image: url('@/assets/joinImg/liubianxbg.png');
						background-size: cover;
						background-position: center;
						div{
							width: 100%;
							height: 100%;
							text-align: center;							
							font-size: 24px;
							font-family: SourceHanSansCN-Medium, SourceHanSansCN;
							font-weight: 500;
							color: #FFFFFF;
							padding-top: 20px;
						}
					}
				}
				.grid-img{
					display: block;
					height: 120px;
					margin: 0 auto;
				}
				.grid-conter{
					display: flex;
					justify-content: space-between;
					width: 100%;
					.conter-box{
						width: 228px;
						height: 104px;
						background: #FFFFFF;
						border-radius: 8px;						
						border: 2px solid #FC526C;
						position: relative;
						display: flex;
						.c-text{
							width: 50%;
							height: 100%;
							text-align: center;
							padding-top: 22px;
							font-size: 24px;
							font-family: SourceHanSansCN-Regular, SourceHanSansCN;
							font-weight: 400;
							color: #FC526C;
							
						}
						.c-line{
							position: absolute;
							top: 0;
							left: 50%;
							width: 2px;
							height: 100%;
							background: #FC526C;							
						}
					}
				}
				.grid-img2{
					display: flex;
					.img_arrow1{
						margin-left: 51px;
					}
					.img_arrow2{
						margin-left: 116px;
					}
				}
				.grid-foot{
					display: flex;
					justify-content: space-around;
					.g-foot-box{
						width: 102px;
						height: 102px;						
						border-radius: 8px;						
						border: 2px solid #FC526C;						
						div{
							width: 100%;
							text-align: center;
							font-size: 24px;
							font-family: SourceHanSansCN-Regular, SourceHanSansCN;
							font-weight: 400;
							color: #FC526C;
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	.graft{
		width: 100%;
		padding-bottom: 50px;
		.graft-text{
			width: 1200px;
			margin: 0 auto;
			margin-top: 100px;
			font-size: 24px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #333333;
		}
		.terminal{			
			width: 280px;
			height: 280px;
			margin: 0 auto;			
			border-radius: 50%;			
			border: 2px solid #FD7589;
			img{
				height: 93px;
				display: block;
				margin: 0 auto;
				margin-top: 70px;
			}
			div{				
				font-size: 24px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #FD7589;
				margin-top: 20px;
				width: 100%;
				text-align: center;
			}
		}
		.shuline{
			height: 40px;
			width: 2px;
			background-color: #FD7589;
			margin: 0 auto;
		}
		.hengline{
			width: 1000px;
			height: 2px;
			background-color: #FD7589;
			margin: 0 auto;
		}
		.minshuline{
			width: 1000px;
			display: flex;
			margin: 0 auto;
			justify-content: space-between;
			div{
				height: 55px;
				width: 2px;
				background-color: #FD7589;
			}
		}
		.line-title{
			width: 1200px;
			display: flex;
			margin: 0 auto;
			justify-content: space-around;
			div{
				width: 160px;
				height: 64px;
				background: #FD7589;
				border-radius: 32px;
				text-align: center;
				line-height: 64px;				
				font-size: 24px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #FFFFFF;
				
			}
		}
		.lot-line{
			width: 1200px;
			display: flex;
			margin: 0 auto;			
			.shu{
				height: 55px;
				width: 2px;
				background-color: #FD7589;
			}
			.xie-left{
				 width: 4px;
				 height: 55px;
				 transform: skew(60deg);
				 background-color: #FD7589;
			}
			.xie-right{
				width: 4px;
				height: 55px;
				transform: skew(-60deg);
				background-color: #FD7589;
			}
		}
		.lot-radius{
			width: 1500px;
			display: flex;
			margin: 0 auto;
			div{
				width: 80px;
				height: 80px;
				border-radius: 50%;
				border: 2px solid #FD7589;				
				text-align: center;
				font-size: 18px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #333333;
				span{
					margin-top: 15px;
					display: block;
				}
			}
		}
	}
	.profit{
		width: 100%;
		background: #F8F9FB;
		padding-bottom: 100px;
		.profit-title{
			width: 1200px;			
			height: 120px;
			background: #FD7589;
			border-radius: 8px;			
			margin: 0 auto;
			text-align: center;
			font-size: 30px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #FFFEFE;
			margin-top: 80px;
			line-height: 120px;
		}
		.lot-line2{
			width: 1200px;	
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			div{
				height: 60px;
				width: 2px;
				background: #FD7589;
			}
		}
		.lot-radius2{
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			div{
				width: 30px;
				height: 30px;
				text-align: center;
				line-height: 30px;
				border: 2px solid #FD7589;
				border-radius: 50%;
				color:#FD7589;
				font-weight: bold;
			}
		}
		.profit-box{
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			.profit-card{
				width: 110px;
				height: 240px;				
				border-radius: 8px;				
				border: 2px solid #FD7589;				
				div{					
					width: 100%;
					text-align: center;
					font-size: 18px;
					font-family: SourceHanSansCN-Regular, SourceHanSansCN;
					font-weight: 400;
					color: #333333;
					margin-top: 30px;
				}
			}
		}
	}
	.core-adv{
		width: 100%;
		background: #FD7589;		
		.join-con2{
			width: 100%;
			padding-top: 100px;
			
			.con-line{
				display: flex;
				width: 100%;
				justify-content: center;
				margin: 10px auto;
				.lin-title{
					width: 300px;				
					line-height: 40px;
					text-align: center;				
					height: 40px;
					font-size: 36px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 500;
					color: #fff;
					margin-top: -25px;
				}
				.line-left{
					width: 600px;
					height: 2px;
					background:linear-gradient(to left, #FFFFFF, #FD7589);				
				}
				.line-right{
					width: 600px;
					height: 2px;
					background: linear-gradient(to right, #FFFFFF, #FD7589);
					
				}
			}
		}		
		.core-box{
			width: 1200px;	
			margin: 0 auto;
			display: flex;
			justify-content: center;
			.core-img{
				background-image: url('@/assets/joinImg/liubianxingbg.png');
				background-size: cover;
				background-position: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 240px;
				height: 208px;				
				div{					
					font-size: 24px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 500;
					color: #FC526C;
					line-height: 48px;
					
				}
			}
			.img-l{
				margin-right: 70px;
				margin-left: 70px;
				margin-top: -100px;
			}
			.img-2{
				margin-right: 70px;
				margin-left: 70px;
				margin-top: -100px;
			}
			
		}
		
	}
	.policy{
		width: 100%;
		background-color: #F8F9FB;
		.policy-text{
			width: 1200px;
			margin: 0 auto;
			margin-top: 100px;
			font-size: 24px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #333333;
		}
		.policy-box{
			width: 1200px;
			margin: 0 auto;
			margin-top: 100px;
			display: flex;
			justify-content: space-between;
			.box-f{
				.box-top{
					width: 280px;
					height: 280px;
					background: #FFFFFF;
					border-radius: 140px 140px 140px 140px;
					opacity: 1;
					border: 2px solid #FC526C;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					img{
						height: 82px;
					}
					div{					
						font-size: 24px;
						font-family: SourceHanSansCN-Medium, SourceHanSansCN;
						font-weight: 500;
						color: #FC526C;
						line-height: 48px;
						margin-top: 40px;
					}
				}
				.box-foot{
					width: 151px;
					margin: 10px auto;
					div{						
						font-size: 18px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #333333;
						line-height: 30px;
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
	.resource{
		width: 100%;
		.resource-con{
			width: 1200px;
			position: relative;
			margin: 0 auto;
			height: 1100px;
			img{
				width: 800px;
				height: 800px;
				position: absolute;
				top:200px;
				left: 200px;
			}
			.resource-text{
				top: 100px;
				right: 500px;
				position: absolute;
				font-size: 30px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #333333;				
			}
			.resource-text2{
				top: 460px;
				left: 0px;
				position: absolute;
				font-size: 30px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #333333;				
			}
			.resource-text3{
				bottom: 160px;
				left: 100px;
				position: absolute;
				font-size: 30px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #333333;				
			}
			.resource-text4{
				bottom: 160px;
				right: 100px;
				position: absolute;
				font-size: 30px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #333333;				
			}
			.resource-text5{
				top: 460px;
				right: 0px;
				position: absolute;
				font-size: 30px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #333333;				
			}
			
		}
	}
	.condition{
		width: 100%;
		background-image: url('@/assets/joinImg/hezuobg.png');
		background-size: cover;
		background-position: center;
		height: 674px;
		.join-con{
			width: 100%;
			padding-top: 100px;
			.con-text{
				width: 100%;
				text-align: center;
				color: #333333;
				font-size: 30px;
				font-weight: bold;
			}
			.con-line{
				display: flex;
				width: 100%;
				justify-content: center;
				margin: 10px auto;
				.lin-title{
					width: 300px;				
					line-height: 40px;
					text-align: center;				
					height: 40px;
					font-size: 36px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 500;
					color: #fff;
					margin-top: -25px;
				}
				.line-left{
					width: 600px;
					height: 2px;
					background:linear-gradient(to left, #FFFFFF, #776E6B);				
				}
				.line-right{
					width: 600px;
					height: 2px;
					background: linear-gradient(to right, #FFFFFF, #776E6B);
					
				}
			}
		}
		.condition-con{
			width: 1200px;
			margin: 70px auto;
			display: flex;
			.con-left{
				width: 360px;				
				
				div{
					border-top: 2px solid rgba(0,0, 0, 0.2);
					width: 100%;
					height: 80px;
					text-align: center;
					line-height: 80px;
					background: rgba(252, 82, 108, 0.5);					
					font-size: 22px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 500;
					color: #FFFFFF;
					
				}
			}
			.con-right{
				width: 800px;
				border-right: 2px solid rgba(252, 82, 108, 0.5);
				border-bottom: 2px solid rgba(252, 82, 108, 0.5);
				div{					
					font-size: 16px;
					font-family: SourceHanSansCN-Normal, SourceHanSansCN;
					font-weight: 400;
					color: #FFFFFF;
					height: 80px;
					
					line-height: 80px;
					text-align: center;
					border-top: 2px solid rgba(252, 82, 108, 0.5);
				}
			}
		}
	}
	.flow{
		width: 100%;
		.flow-box{
			width: 1200px;
			display: flex;
			margin: 0px auto;
			margin-top: 100px;
			div{
				width: 167px;
				height: 80px;
				font-size: 22px;
				text-align: center;
				line-height: 80px;
				color: #fff;
			}
			div:nth-child(odd){
				
				background: rgba(252, 82, 108, 0.5);
			}
			div:nth-child(even){
				
				background: #B2B2B2;
			}
		}
		.foot-telphon{
			width: 100%;
			text-align: center;
			font-size: 24px;
			
			margin-top: 50px;
			padding-bottom: 50px;
			span{
				color: #FC526C;
			}
		}
	}
	.foot-store{
		width: 100%;
		padding-bottom: 50px;
		.store-img{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 30px;			
			img{
				margin-left: 30px;
			}
		}
		.store-image{
			margin-top: 70px;	
		}
	}
}
</style>