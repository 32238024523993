import CryptoJS from 'crypto-js';
import {Base64} from 'js-base64';

const KEY = "0JrF8oS+Q/eeb5LczgMPlA=="

let time = new Date()
//加密
export function aesDecrypt() {		
	let mapdata ={
		"rt": Date.now()*1000,		
		"token": "", 
		"vt": 40000000
	}				
	let word = JSON.stringify(mapdata);	
			
	const key = CryptoJS.enc.Base64.parse(KEY);
	  const encrypted = CryptoJS.AES.encrypt(word,key, {		  
		  mode: CryptoJS.mode.ECB,		  
		  padding: CryptoJS.pad.Pkcs7
		})						
		let tk = CryptoJS.enc.Base64.stringify(encrypted.ciphertext).toString();						
		return tk
}
