<template>
	<div class="appJoin">
		<img src="@/assets/app/appJoin.jpg" />
	</div>
</template>

<script>
</script>

<style scoped lang="scss">
.appJoin{
	width: 100%;
	height: 100vh;
	overflow: auto;
	
	img{
		width: 100%;
	}
}
</style>