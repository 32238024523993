<template>
	<div>
		<img class="contact-img" src="@/assets/img/lianxi_banner.jpg" />
		<div class="con">
			<div class="seve-head">
				<div class="wuhan-img" >
					<img src="@/assets/img/wuhan.jpg" alt=""/>
				</div>
				<div class="seve-con">
					<div class="head-title">青橙（武汉）技术有限公司</div>
					<div class="head-mintitle" >企业总部（武汉）</div>
					<div class="head-con">地址：湖北省武汉市东湖新技术开发区木香路2号高投双创工坊･创业资本汇园区</div>
					<div class="head-con">联系电话： 400 8286 8968</div>
					<div class="head-con">邮编：430000</div>
					<div class="head-con">邮箱：MyOrange@126.com</div>
				</div>
				
			</div>
			<div class="seve-head">
				<div class="wuhan-img">
					<img src="@/assets/img/xian.jpg" alt=""/>
				</div>				
				<div class="seve-con"  >
					<div class="head-mintitle">陕西分公司（西安）</div>
					<div class="head-con" style="margin-top: 10px;">地址：陕西省西安市长安区西安电子科技大学（南校区）星火空间</div>
					<div class="head-con">联系电话：400 8286 8968</div>
					<div class="head-con">邮编：710216</div>						
				</div>				
			</div>
			<div class="seve-head">
				<div class="wuhan-img">
					<img src="@/assets/img/nanning.jpg" alt=""/>
				</div>		
				<div class="seve-con" >
					<div class="head-mintitle">广西分公司（南宁）</div>
					<div class="head-con" style="margin-top: 10px;">地址：广西壮族自治区南宁市高新技术开发区中关村科技园</div>						
					<div class="head-con">联系电话： 0771 8286 8968</div>
					<div class="head-con">邮编：530000</div>
					
				</div>				
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
.contact-img{
	display: block;
	width: 100%;
	
}
.con{
	.seve-head{
		width: 100%;			
		.wuhan-img{
			width: 100%;
			height: 230px;
			border: 2px solid #FDA8B5;
			border-radius: 8px;
			margin-top: 20px;			
			img{
				width: 94%;								
				margin-left: 12px;
				margin-top: 12px;
				margin-right: 12px
			}
		}		
		.seve-con{
			padding-bottom: 30px;							
			.head-title{
				font-size: 20px;				
				color: #FC526C;
				font-weight: bold;
				margin-top: 20px;
			}
			.head-mintitle{
				font-size: 16px;
				color: #333333;			
				font-weight: bold;				
				margin-top: 10px;
			}
			.head-con{
				font-size: 14px;
				margin-top: 10px;
				color: #666666;
				font-weight: bold;
			}
		}
		
	}
	
}
</style>