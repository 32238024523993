import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
//import Layouts from '@/layouts/index.vue'
import Home from '@/views/home/index.vue'
import About from '@/views/about/index.vue'
import Business from '@/views/business/index.vue'
import Join from '@/views/join/index.vue'
import Contact from '@/views/contact/index.vue'
import Train from '@/views/train/index.vue'
import Opinion from '@/views/opinion/index.vue'
import Safe from '@/views/safe/index.vue'
import AppTrain from '@/views/appHtml/appTrain.vue'
import MyLevel from '@/views/appHtml/myLevel.vue'
import CreditAuthentication from '@/views/appHtml/creditAuthentication.vue'
import  MyAssure from '@/views/appHtml/myAssure.vue'
import  Guide from '@/views/appHtml/guide.vue'
import  Certificate from '@/views/appHtml/certificate.vue'
//import  UserPrivacy from '@/views/appHtml/userPrivacy.vue'
//import  PrivacyAgreement from '@/views/appHtml/privacyAgreement.vue'
import  AppJoin from '@/views/appHtml/appJoin.vue'
//import  MemberUserPrivacy from '@/views/appHtml/memberUserPrivacy.vue'
//import  MemberPrivacyAgreement from '@/views/appHtml/memberPrivacyAgreement.vue'
import  AppPayCode from '@/views/payCode/index.vue'
import  Privacy from '@/views/privacy/index.vue'
import Logout from '@/views/appHtml/logout.vue'
import { ref, watch } from "vue";

//移动端
import AppHome from '@/viewApp/home/appHome.vue'
import AppPrivacy from '@/viewApp/appPrivacy/index.vue'
import AppAbout from '@/viewApp/appAbout/index.vue'
import JoinApp from '@/viewApp/appJoin/index.vue'
import AppSafe from "@/viewApp/appSafe/index.vue"
import AppBusiness from '@/viewApp/appBusiness/index.vue'
import AppContact from '@/viewApp/appContact/index.vue'
import AppTrainT from '@/viewApp/appTrain/index.vue'
import AppOpinion from '@/viewApp/appOpinion/index.vue'

let routes = []
const routerPC = [
	{
	  path: '/',
	  name: 'layouts',
	  component: () => import("@/layouts/index.vue"),
		children: [
		  {
		    path: "/",
		    name: "Home",
		    meta: {title: "首页"},
		    component: Home
		  },
		  {
		    path: "about",
		    name: "About",
		    meta: { title: "关于我们" },
		    component: About
		  },
		  {
		    path: "business",
		    name: "Business",
		    meta: { title: "业务与服务" },
		    component: Business
		  },
		  {
		    path: "join",
		    name: "Join",
		    meta: { title: "合作加盟" },
		    component: Join
		  },
		  {
		    path: "contact",
		    name: "Contact",
		    meta: { title: "联系我们" },
		    component: Contact
		  },
		  {
		    path: "train",
		    name: "Train",
		    meta: { title: "培训中心" },
		    component: Train
		  },
		  {
		    path: "opinion",
		    name: "Opinion",
		    meta: { title: "发表意见" },
		    component: Opinion
		  },
		  {
		    path: "safe",
		    name: "Safe",
		    meta: { title: "安全中心" },
		    component: Safe
		  },
		  {
		    path: "privacy",
		    name: "Privacy",
		    meta: { title: "隐私协议" },			
		    component: Privacy
		  }		  
		  ]
	},
	//app跳转
		{
		  path: "/app_train",
		  name: "AppTrain",
		  meta: { title: "培训" },
		  component: AppTrain,
		},
		{
		  path: "/app_myLevel",
		  name: "MyLevel",
		  meta: { title: "我的等级" },
		  component: MyLevel,
		},
		{
		  path: "/app_creditAuthentication",
		  name: "CreditAuthentication",
		  meta: { title: "信用认证" },
		  component: CreditAuthentication,
		},
		{
		  path: "/app_myAssure",
		  name: "MyAssure",
		  meta: { title: "我的保障" },
		  component: MyAssure,
		},
		{
		  path: "/app_guide",
		  name: "Guide",
		  meta: { title: "向导" },
		  component: Guide,
		},		
		{
		  path: "/app_logout",
		  name: "Logout",
		  meta: { title: "注销协议" },
		  component: Logout,
		},
		{
		  path: "/app_certificate",
		  name: "Certificate",
		  meta: { title: "证照信息" },
		  component: Certificate,
		},
		{
		  path: "/app_logout",
		  name: "Logout",
		  meta: { title: "注销协议" },
		  component: Logout,
		},
		{
		  path: "/app_appJoin",
		  name: "AppJoin",
		  meta: { title: "招商加盟" },
		  component: AppJoin,
		},
]
const routerMB = [
	{
		path: "/",
		name: "Home",
		meta: { title: "首页" },
		component: () => import('@/viewApp/home/index.vue')
	},
	{
	  path: "/app_certificate",
	  name: "Certificate",
	  meta: { title: "证照信息" },
	  component: Certificate,
	},
	{
	  path: "/app_logout",
	  name: "Logout",
	  meta: { title: "注销协议" },
	  component: Logout,
	},
	{
	  path: "/app_appJoin",
	  name: "AppJoin",
	  meta: { title: "招商加盟" },
	  component: AppJoin,
	},
	{
	  path: "/app_payCode",
	  name: "AppPayCode",
	  meta: { title: "支付信息" },
	  component: AppPayCode,
	},
	{
	  path: '/appLayouts',
	  name: 'AppLayouts',
	  component: () => import("@/appLayouts/index.vue"),
	  children: [
	    {
	      path: "/appHome",
	      name: "AppHome",
	      meta: {title: "app首页"},
	      component: AppHome
	    },
		{
		  path: "/privacy",
		  name: "AppPrivacy",
		  meta: { title: "app隐私协议" },					
		  component:AppPrivacy
		},
		{
		  path: "/about",
		  name: "AppAbout",
		  meta: { title: "app关于我们" },					
		  component:AppAbout
		},
		{
		  path: "/join",
		  name: "JoinApp",
		  meta: { title: "合作加盟" },
		  component: JoinApp
		},
		{
		  path: "/safe",
		  name: "AppSafe",
		  meta: { title: "安全中心" },
		  component: AppSafe
		},
		{
		  path: "/business",
		  name: "AppBusiness",
		  meta: { title: "业务与服务" },
		  component: AppBusiness
		},
		{
		  path: "/contact",
		  name: "AppContact",
		  meta: { title: "联系我们" },
		  component: AppContact
		},
		{
		  path: "/train",
		  name: "AppTrainT",
		  meta: { title: "培训中心" },
		  component: AppTrainT
		},
		{
		  path: "/opinion",
		  name: "AppOpinion",
		  meta: { title: "发表意见" },
		  component: AppOpinion
		},
		]
	  },
	  
]
const screenWidth = ref()
screenWidth.value = document.body.clientWidth
if (screenWidth.value <= 758) {    
    routes = routerMB
} else {
    routes = routerPC
}

const router = createRouter({
 // history: createWebHashHistory(),
	history: createWebHistory(),
	routes
})

router.afterEach(() => {
   
    window.scrollTo(0,0);
});
export default router
