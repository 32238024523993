import request from '@/utils/axios.js'
//订单详情
export const getPayOrderInfo = (data) => {
  return request({
    //url: `/api/order/orderinfo/open/info?tk=${tk}&orderNo=${data}`,    
	url: '/api/order/orderinfo/open/info', 
	params: data,
    method: 'GET'
	
  })
}
//h5支付
export const getPayh5 = (data) => {
  return request({    
	url: `/api/order/open/wc/pay/h5?tk=${data.tk}&orderNo=${data.orderNo}`, 	
    method: 'POST'
	
  })
}

//获取公众号openid
export const getWxOpenid = (code) => {
    return request({
        url: `/api/third/wx/official/openid?code=${code}`,
        method: 'POST'

    })
}

//获取公众号openid
export const orderPrepay = (data) => {
    return request({
        url: `/api/order/wc/prepay/v2`,
        method: 'POST',
        data
    })
}
