<template>
	<div class="certificate">
		<div class="title">资质证照</div>
		<img src="@/assets/app/ygwr.png"  class="prevent-save" oncontextmenu='return false' alt="" />
	</div>
</template>

<script lang="ts" setup>
	
</script>

<style scoped lang="scss">
.certificate{
	width: 100%;
	height: 100%;
	.title{
		width: 100%;
		text-align: center;
		margin-top: 20px;
		font-size: 16px;
		
	}
	img{
		display: block;
		margin: 20px auto;
		width: 96%;
		pointer-events: none;
		user-select: none;
		-webkit-touch-callout: none;
	}
	
	
}	
</style>