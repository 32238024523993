<template>
	<div class="logout">
		<div class="title">《用户注销协议》</div>
		<div class="stitle">账户注销协议（心巢（员工端））</div>
		<p>在您确认注销您的账户之前，请您充分阅读、理解并同意下列事项（特别是以粗体标注的内容），如您不同意下列任一内容，将无法准确理解任何条款的含义，请不要进行账户注销操作，账户一旦被注销将不可恢复。您通过页面确认申请注销，视为您同意接受本协议所有内容。</p>
		<p>一、账户注销将会对您产生如下影响：</p>
		<p>1. 账户注销后，您将无法登录、使用该账户，即无法再以此账户登录/使用/继续使用相关产品与服务；</p>
		<p>2. 账户注销后，您曾通过该账户登录、使用的产品与服务下的所有内容、信息、数据、记录将会被删除或匿名化处理，您无法再 检索、访问、获取、继续使用和找回，也无权要求我们找回 （但法律法规另有规定或监管部门另有要求的除外），包括： </p>
		<p>（1） 该账户下个人资料（例如：头像、昵称等）、实名认证信息（例如：绑定手机号、微信号、Apple ID等）、其他个人信息；</p>
		<p>（2） 该账户下各产品/服务的信息以及权益信息（例如：电子简历、投递和沟通记录、培训记录等）；</p>
		<p>（3） 该账户下业务订单和交易信息（例如：订单记录、支付记录、提现记录等） </p>
		<p>（4） 该账户下沟通/发表/评论记录（例如：发表的音频、视 频、图片、照片、评论、互动等）； </p>
		<p>（5） 该账户下的历史记录、信息、数据以及使用各产品/服务 时暂存的其他信息</p>
		<p>3. 账户注销后，您的账户内资产将会被清空，无法再次找回，包括：钱包资金、金豆、红包、卡券、保证金、直播产品、培训课程等各类权益。</p>
		<p>4. 账户一旦注销完成，将无法恢复，请您在操作之前自行备份账户相关的所有信息和数据。注销账户，您通过账户进行登录的各网站、心巢（员工端）APP 所有记录将无法找回，您无法再登录、使用前述服务，您曾获得的所有权益均视为您自行放弃，将无法继续使用。您理解并同意，我们无法协助您恢复前述服务，即使您在注销后以同一手机号码再次注册账户，此时该账户为新的用户账户。</p>
		<p>5. 您充分知晓账户注销将导致我们终止为您提供服务，您与我们曾签署过的相关用户协议、服务协议、其他权利义务性文件等相应终止，依据本协议其他条款另行约定不得终止的或依其性质不能终止的除外。</p>
		<p>二、在您申请注销前，您应当确保账户同时满足以下条件：</p>
		<p>1. 账户无未完成的订单，无已提供服务未支付/提现的订单；</p>
		<p>2. 账户内无资产、无欠款、无未使用储值卡；</p>
		<p>3. 账户无任何纠纷，包括投诉举报、被投诉举报或处罚；</p>
		<p>4. 账户可正常使用且无任何被限制记录；</p>
		<p>5. 账户对应的支付账户已注销，且已妥善处理了支付账户下的所有问题；</p>
		<p>6. 满足其他网站、APP账户注销条件。</p>
		<p>三、其他</p>
		<p>1. 您应确保您有权决定该账户的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何投诉争议，由您自行承担并使心巢（员工端）免责。</p>
		<p>2. 您充分知晓账户注销将导致我们终止为您提供服务，您与我们曾 
签署过的相关用户协议、服务协议、其他权利义务性文件等相 
应终止，依据本协议其他条款另行约定不得终止的或依其性质 
不能终止的除外。</p>
		<p>二、在您申请注销前，您应当确保账户同时满足以下条件：</p>
		<p>1. 账户无未完成的订单，无已提供服务未支付/提现的订单；</p>
		<p>2. 账户内无资产、无欠款、无未使用储值卡；</p>
		<p>3. 账户无任何纠纷，包括投诉举报、被投诉举报或处罚；</p>
		<p>4. 账户可正常使用且无任何被限制记录；</p>
		<p>5. 账户对应的支付账户已注销，且已妥善处理了支付账户下的所有问题；</p>
		<p>6. 满足其他网站、APP账户注销条件。</p>
		<p>三、其他</p>
		<p>1.您应确保您有权决定该账户的注销事宜，不侵犯任何第三方的合
法权益，如因此引发任何投诉争议，由您自行承担并使心巢（员工端）免责。</p>
		<p>2.您应按照平台公示的流程进行账户注销，且您承诺该账户注销申
请一经提交，您不会以任何理由要求平台予以撤销。</p>
		<p>3.如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、
仲裁、国家有权机关调查等，平台有权自行终止注销流程而无
需另行得到您的同意。</p>
		<p>4.注销此账户并不代表此账户注销前的行为和相关责任得到豁免或
减轻。</p>
		<p>5.如您仍执意注销您的账户，可点击按钮申请。如有其他问题，可
联系客服咨询。</p>
		
	</div>
</template>

<script>
</script>

<style scoped lang="scss">
.logout{
	width: 100%;
	height: 100%;
	font-size: 1.2rem;
	.title{
		font-size: 1.7rem;
		width: 100%;
		text-align: center;
		margin-top: 2rem;
	}
	.stitle{
		font-size: 1.4rem;
		width: 100%;
		text-align: center;
		margin-top: 1rem;
	}
	p{
		line-height: 2rem;
		width: 98%;
		margin-left: 1%;
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
}
</style>