<template>
	<div class="join">
		<img src="@/assets/app/appJoin.jpg" alt="">
	</div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
.join{
	img{
		width: 100%;
	}
}
</style>