<template>
	<div>
		培训
	</div>
</template>

<script>
</script>

<style>
</style>