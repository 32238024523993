<template>
	<div>
		<img class="opinion-img" src="@/assets/img/fabiao_banner.jpg" alt="">
		<div class="opinion-title" style="margin-top: 100px;">我们很注重阁下的建议和意见</div>
		<div class="opinion-title">您可通过以下渠道反馈您的意见</div>
		<div class="opinion-card">
			<div class="opinion-box">
				<div class="box-img">
					<div class="back-box">ifamily@tom.com</div>
				</div>
				<div class="box-label">邮箱反馈</div>
			</div>
			<div class="opinion-box">
				<div class="box-img2">
					<div class="back-box2">
						<img src="@/assets/safeop/QQCode@2x.png" alt="" />
					</div>
				</div>
				<div class="box-label">加入QQ群</div>
			</div>
			<div class="opinion-box">
				<div class="box-img2">
					<div class="back-box2">
						<img src="@/assets/safeop/WEIXINCode@2x.png" alt="" />
					</div>
				</div>
				<div class="box-label">企业微信</div>
			</div>
			<div class="opinion-box">				
				<img class="box-img3" src="@/assets/safeop/photo@2x.png" alt="" />
				<div class="box-label">服务电话</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
.opinion-img{
	width: 100%;
	height: 500px;
}
.opinion-title{
	width: 100%;
	text-align: center;
	font-size: 35px;
	margin-top: 30px;
	}
.opinion-card{
	width: 1200px;
	margin: 100px auto;
	display: flex;
	justify-content: space-between;
	.opinion-box{
		width: 240px;
		height: 300px;
		.box-label{
			width: 100%;
			height: 60px;
			text-align: center;
			line-height: 60px;
			font-size: 24px;
		}
		.box-img{
			width: 100%;
			height: 240px;
			background-image: url('@/assets/safeop/emeit@2x.png');
			background-size: cover;
			background-position: center;
			border-radius: 8px;
			
			.back-box{
				background: rgba(0, 0, 0, 0.5);
				width: 100%;
				height: 100%;
				text-align: center;
				line-height: 240px;
				font-size: 18px;
				color: #ffffff;
				border-radius: 8px;
			}
		}
		.box-img2{
			width: 100%;
			height: 240px;
			background-image: url('@/assets/safeop/QQback@2x.png');
			background-size: cover;
			background-position: center;
			border-radius: 8px;
			.back-box2{
				background: rgba(0, 0, 0, 0.5);
				width: 100%;
				height: 100%;				
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				img{
					width: 128px;
					height: 128px;			
					
				}
			}
			
		}
		.box-img3{
			width: 100%;
			height: 240px;
			border-radius: 8px;
			display: block;
		}
	}
	
}
</style>